import React from 'react';
import { NavLink } from 'react-router-dom';
import hytteboker from '../data/hytteboker.json';
 
const HyttebokList = () => {
    console.log(hytteboker.hytteboker);
    return (
        <ul>
            {
            hytteboker.hytteboker.map((hyttebok) =>
            <li key={hyttebok.key}><NavLink to={{
                pathname:'/Hyttebok',
                hyttebok: hyttebok
                }}>{hyttebok.title}</NavLink></li>
            )
            }
      </ul>
    );
}
 
export default HyttebokList;
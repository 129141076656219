import React from 'react';
import HyttebokList from './Hyttebok_List';
import vetahytta_skilt from '../images/vetahytta_skilt.jpg';
import vetahytta_hytten from '../images/vetahytta_hytten.jpg';
import '../App.css';


 
const home = () => {
    return (
        <div className="App">
        <div className="images">
          <img src={vetahytta_skilt} alt="Skilt på Vetahytten" />
          <img src={vetahytta_hytten}  alt="Bilde av hytten sett fra sør-øst" />
        </div>
        <div className="content">
          <h1>VETAHYTTA</h1><br/>
          <h3>Hyttebøker</h3>
          <HyttebokList />
        </div>
      </div>
    );
}
 
export default home;
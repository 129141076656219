import React from 'react';
import HyttebokList from './Hyttebok_List';
import loading_gif from '../images/loading.gif';
import { NavLink } from 'react-router-dom';
import '../App.css';

const hyttebok = (data) => {

    function hideLoader() {
        let loader_img = document.getElementById('loader');
        console.log(loader_img);
    }

if(data.location.hyttebok === undefined) {

    return (
        <div className="App">
            <div className="content">
            <h1>Hyttebøker</h1>
            <p>Velg en bok:</p>
            <ul>
                <HyttebokList />
            </ul>
            <br/><br/>
            <div className="hyttebok_navigation">
                    <NavLink to="/">« Tilbake</NavLink>
            </div>
            </div>
        </div>
    );

} else {

    return (
        <div className="App">
            <div>
                <div className="iframe_wrapper">
                    <iframe src={data.location.hyttebok.iframe_url} frameBorder="0" allowFullScreen onLoad={hideLoader()}></iframe>
                    <img id="loader" src={loading_gif} alt="Laster hyttebok" />
                </div>
                <div className="hyttebok_navigation">
                    <NavLink to="/">« Tilbake</NavLink>
                    <NavLink to="/Hyttebok">Velg en annen bok »</NavLink>
                </div>
            </div>
      </div>
    );
}


}
 
export default hyttebok;